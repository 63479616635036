import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import moment from 'moment';
import uuid from 'uuid';
import { Map, Set } from 'immutable';
import styled from 'styled-components';
import { Code } from 'react-content-loader';
import Sidebar from 'arui-feather/sidebar';
import Spin from 'arui-feather/spin';
import axios from 'axios';
import TodoCol from '../tools/TodoCol';

import all_export from '../../../assets/img/all_export_.svg';
import CacheAPI from '../../../api/CacheAPI';

const ITEMS = [
  {
    status: 'new',
    colLabel: 'Новые задачи',
  },
  {
    status: 'in_progress',
    colLabel: 'В работе',
  },
  {
    status: 'finished',
    colLabel: 'Завершенные',
  },
];

export default function TodoPanel(props) {
  return (
    <Wrapper>
      {ITEMS.map((a, i) => (
        <Col key={i}>
          <ColTop>{a.colLabel}</ColTop>
          <TodoCol status={a.status} />
        </Col>
      ))}

      <ExpImg
        src={all_export}
        onClick={async () => {
          await CacheAPI.setDefaultTasks();
        }}
      />

      <ExpImg
        style={{ right: 60 }}
        src={all_export}
        onClick={async () => {
          await CacheAPI.setEmptyTasks();
        }}
      />
    </Wrapper>
  );
}

const ColTop = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 22px;
  color: white;
  font-weight: bold;
  padding-left: 10px;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding-left: 15px;
`;

const ExpImg = styled.img`
  width: 42px;
  height: 42px;
  position: fixed;
  z-index: 100;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
`;
